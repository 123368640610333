import React, { memo } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import styles from '../styles/layouts/memberCarousel.module.css';
import { NextArrow, PrevArrow } from '../components/Arrow';
import defaultImage from '../assets/avatar.jpg'; // Imagem padrão

const MemberCarousel = memo(({ members }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />, // Usando as setas personalizadas
    prevArrow: <PrevArrow />, // Usando as setas personalizadas
    responsive: [
      {
        breakpoint: 1024, // Para telas médias
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768, // Para tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 480, // Para celulares
        settings: {
          slidesToShow: 1, // Mostrar 1 por vez
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <div className={styles.carouselContainer}>
      <Slider {...settings} className={styles.slider}>
        {members.map((member, index) => (
          <div key={index} className={styles.member}>
            <div className={styles.memberCard}>
              <div className={styles.memberImageContainer}>
                <div className={styles.memberImage}>
                  <img 
                    src={member.image || defaultImage} 
                    alt={member.name} 
                    loading="lazy"  // Lazy loading das imagens
                  />
                </div>
              </div>
              <div className={styles.memberInfo}>
                <p className={styles.memberName}>{member.name}</p>
                <p className={styles.memberRole}>{member.role}</p>
                <div className={styles.socialIcons}>
                  {member.github ? (
                    <a href={member.github} target="_blank" rel="noopener noreferrer" className={styles.socialIcon}>
                      <i className="fab fa-github"></i>
                    </a>
                  ) : (
                    <span className={styles.socialIconUnavailable}>
                      <i className="fab fa-github"></i>
                    </span>
                  )}
                  {member.linkedin ? (
                    <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className={styles.socialIcon}>
                      <i className="fab fa-linkedin"></i>
                    </a>
                  ) : (
                    <span className={styles.socialIconUnavailable}>
                      <i className="fab fa-linkedin"></i>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
});

export default MemberCarousel;
