import React from 'react';
import styles from '../styles/layouts/card.module.css';

function Card({ img, title, text }) {
  return (
    <div className={styles.card}>
      <div className={styles.imgContainer}>
        <img src={img} alt="Imagem do Card" />
      </div>
      <div className={styles.card_text}>
        <h5>{title}</h5>
        <p>{text}</p>
      </div>
    </div>
  );
}

export default Card;
