import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from '../styles/components/navbar.module.css';
import logo from '../assets/logo_encurtada.png';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  // Verifica se o link atual está ativo
  const isActive = (path) => location.pathname === path;

  return (
    <nav className={styles.navbar}>
      <div className={styles.headerContainer}>
        <Link to="/" onClick={closeMenu}>
          <img src={logo} alt="linked" className={styles.logo} />
        </Link>
        <div
          className={`${styles.hamburger} ${menuOpen ? styles.toggle : ''}`}
          onClick={toggleMenu}
        >
          <div className={styles.bar1}></div>
          <div className={styles.bar2}></div>
          <div className={styles.bar3}></div>
        </div>
        <ul className={`${styles.list} ${menuOpen ? styles.active : ''}`}>
          <li className={`${styles.item} ${isActive('/') ? styles.activeLink : ''}`}>
            <Link to="/" onClick={closeMenu}>Home</Link>
          </li>
          <li className={`${styles.item} ${isActive('/services') ? styles.activeLink : ''}`}>
            <Link to="/services" onClick={closeMenu}>Serviços</Link>
          </li>
          <li className={`${styles.item} ${isActive('/cases') ? styles.activeLink : ''}`}>
            <Link to="/cases" onClick={closeMenu}>Cases</Link>
          </li>
          <li className={`${styles.item} ${isActive('/company') ? styles.activeLink : ''}`}>
            <Link to="/company" onClick={closeMenu}>Sobre</Link>
          </li>
          <li className={`${styles.item} ${styles.budget} ${isActive('/budget') ? styles.activeLink : ''}`}>
            <Link to="/budget" onClick={closeMenu}>Contato</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
