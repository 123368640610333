import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

import styles from '../styles/layouts/aboutUsCompany.module.css';
import equipe1 from '../assets/equipe2.jpg';
import equipe2 from '../assets/equipe1.jpeg';
import equipe3 from '../assets/equipe.png';
import equipe4 from '../assets/Equipe3.jpg';
import { NextArrow, PrevArrow } from '../components/Arrow';

const AboutUsCompany = () => {
  const images = [
    equipe2, equipe1, equipe3, equipe4
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 1000,
    cssEase: "linear",
  };

  return (
    <section className={styles.about_us_section}>
      <h2>Nossa História</h2>
      <div className={styles.image_container}>
        <Slider {...settings}>
          {images.map((img, index) => (
            <div key={index} className={styles.slide}>
              <img src={img} alt={`equipe ${index + 1}`} className={styles.about_image} />
            </div>
          ))}
        </Slider>
      </div>
      <div className={styles.about_content}>
        <div className={styles.text}>
          <p>A Linked é a Empresa Júnior do curso de Ciência da Computação da Universidade Federal de São João del-Rei. Criada e gerida por estudantes, nossa missão é oferecer soluções tecnológicas inovadoras, garantindo qualidade e satisfação ao cliente. Promovemos o desenvolvimento pessoal e profissional dos nossos membros através da execução de projetos de alta qualidade, contribuindo para o progresso econômico e empresarial.</p>
          <p>Valorizamos a inovação e o acolhimento, direcionando nossos lucros para criar oportunidades tanto para nossos membros quanto para a comunidade da computação. Compartilhamos nosso conhecimento para democratizar o acesso ao mercado de computação, trabalhando para construir um ambiente colaborativo e acessível a todos.</p>
        </div>
        <div className={styles.data}>
          <div className={styles.item}>
              <h1>42</h1>
              <p>Projetos executados</p>
          </div>
          <div className={styles.item}>
              <h1>14</h1>
              <p>Anos de atividade</p>
          </div>
          <div className={styles.item}>
              <h1>27</h1>
              <p>Membros ativos</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsCompany;
