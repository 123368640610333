import React from 'react';
import styles from '../styles/layouts/aboutUsHome.module.css';
import LinkButton from './LinkButton';
import mascote from '../assets/forte_no_escritorio.png';

const AboutUsHome = () => {
  return (
    <section className={styles.about_us_section}>
      <h2>QUEM SOMOS</h2>
      <p className={styles.subheading}>Conheça um pouco da nossa história!</p>
      <div className={styles.about_content}>
        <div className={styles.text}>
          <p>A Linked é a Empresa Júnior do curso de Ciência da Computação da Universidade Federal de São João del-Rei. Criada e gerida por estudantes, nossa missão é oferecer soluções tecnológicas inovadoras, garantindo qualidade e satisfação ao cliente. Promovemos o desenvolvimento pessoal e profissional dos nossos membros através da execução de projetos de alta qualidade, contribuindo para o progresso econômico e empresarial.</p>
          <p>Valorizamos a inovação e o acolhimento, direcionando nossos lucros para criar oportunidades tanto para nossos membros quanto para a comunidade da computação. Compartilhamos nosso conhecimento para democratizar o acesso ao mercado de computação, trabalhando para construir um ambiente colaborativo e acessível a todos.</p>
        </div>
        <div className={styles.image_container}>
          <img src={mascote} alt="Mascote Linked" className={styles.about_image}/>
          <div className={styles.button_container}>
            <LinkButton to="/company" text="Saiba mais" className="btnSecond"/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsHome;
