import React from 'react';

/* layouts */
import AboutUsCompany from '../layouts/AboutUsCompany';
import Mvv from '../layouts/Mvv';
import MemberCarousels from '../layouts/MemberCarousels'; 
import Partners from '../layouts/Partners';

const membersData = {
  diretores: [
    { name: 'João Antônio', role: 'Presidente', image: require('../assets/Membros/Atual/João_Antonio_da_Linked.jpg'), linkedin: 'https://www.linkedin.com/in/scjoaoantonio/', github: 'https://github.com/scjoaoantonio' },
    { name: 'André Chagas', role: 'Diretor de Projetos', image: require('../assets/Membros/Atual/Andre_Chagas.jpg'), github: 'https://github.com/andrechagaslima' },
    { name: 'Lucas Leite', role: 'Diretor Comercial', linkedin: 'http://www.linkedin.com/in/lucas-leite-888953299', github: 'https://github.com/lucaselc' },
    { name: 'Lucas Bernardes', role: 'Diretor Administrativo', image: require('../assets/Membros/Atual/Lucas_Bernardes.jpg'), linkedin: 'https://www.linkedin.com/in/lucas-eduardo-bernardes-de-paula-5615b7256/', github: 'https://github.com/LucasBernardess' },
  ],
  setorProjetos: [
    { name: 'Bruno Costa', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Bruno.jpg') },
    { name: 'Messias Feres', role: 'Assessor de Projetos', linkedin: 'https://www.linkedin.com/in/messiasfcm/', github: 'https://github.com/MessiasFCM' },
    { name: 'Davi Greco', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Davi_Greco.jpg'), linkedin: 'https://www.linkedin.com/in/davi-greco-4285b9275/', github: 'https://github.com/DaviGreco' },
    { name: 'Davi Gomes', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Davi_Gomes.jpg'), linkedin: 'https://www.linkedin.com/in/davi-gomes-coelho-49b24a184', github: 'https://github.com/davigpc' },
    { name: 'Dilvonei Alves', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Dilvonei.jpg'), linkedin: 'https://www.linkedin.com/in/dilvonei-alves-lacerda-05328a228/', github: 'https://github.com/DilvoneiL' },
    { name: 'Elias Mendes', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Elias.jpg'), linkedin: 'https://www.linkedin.com/in/elias-m-1390b2141/' },
    { name: 'Ian Nunes', role: 'Assessor de Projetos', linkedin: 'https://www.linkedin.com/in/ian-nunes-379686266/' },
    { name: 'Luiz Felipe', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Luiz_Felipe.jpg') },
    { name: 'Lucas Rivetti', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Rivetti.jpg'), github: 'https://github.com/LucasRivetti' },
    { name: 'Vitor Niess', role: 'Assessor de Projetos', image: require('../assets/Membros/Atual/Vitor_Niess.jpg') },
  ],
  setorComercial: [
    { name: 'Antônio Gustavo', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Antonio.jpg') },
    { name: 'Ana Júlia', role: 'Assessora Comercial', image: require('../assets/Membros/Atual/Julia.jpg'), linkedin: 'https://www.linkedin.com/in/ana-julia-silva-ledo-b8386828a', github: 'https://github.com/julialedo' },
    { name: 'Guilherme Anicio', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Guilherme_Sasa.jpg'), linkedin: 'https://www.linkedin.com/in/guilherme-m-anicio/' },
    { name: 'Leonardo Patias', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Leonardo.jpg') },
    { name: 'Matheus de Oliveira', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Matheus.jpg'), linkedin: 'https://www.linkedin.com/in/mateuso-brito/' },
    { name: 'Gustavo Euller', role: 'Assessor de Finanças', image: require('../assets/Membros/Atual/Gustavo_Euller.jpg') },
    { name: 'Thaís Apolinário', role: 'Assessora Comercial', image: require('../assets/Membros/Atual/Thais_Apolinario.jpg'), linkedin: 'https://www.linkedin.com/in/tha%C3%ADs-apolin%C3%A1rio-196994259/' },
    { name: 'Vitor José', role: 'Assessor Comercial', image: require('../assets/Membros/Atual/Vitor_José.jpg') },
  ],
  setorGestao: [
    { name: 'Brain Alves', role: 'Assessor Administrativo', image: require('../assets/Membros/Atual/Braian.jpg'), linkedin: 'https://www.linkedin.com/in/brian-alves/' },
    { name: 'Marco Aurélio', role: 'Assessor Administrativo', image: require('../assets/Membros/Atual/Marcu.jpg'), linkedin: 'https://www.linkedin.com/in/marcoquaglietta/' },
    { name: 'Lara Caroline', role: 'Assessora Administrativa', image: require('../assets/Membros/Atual/Lara.jpg') },
    { name: 'Oscar Alves', role: 'Assessor Administrativo', image: require('../assets/Membros/Atual/Oscar.jpg'), linkedin: 'https://www.linkedin.com/in/oscar-alves-11ab00172/' },
  ],
  posJuniores: [
    { name: 'Rafael Marques', role: 'Ex-Presidente', image: require('../assets/Membros/Pós_Juniores/Rafael_Marques.jpeg'), linkedin: 'https://www.linkedin.com/in/rafael-campos-656559217/', github: 'https://github.com/Markesxd' },
    { name: 'Douglas José', role: 'Ex-Assessor de Projetos', image: require('../assets/Membros/Pós_Juniores/Douglas_José_Barboza.jpg'), linkedin: 'https://www.linkedin.com/in/douglasjosebarboza/', github: 'https://github.com/douglasjosebarboza' },
    { name: 'Joice Cristina', role: 'Ex-Representante de Projetos', image: require('../assets/Membros/Pós_Juniores/Joice_Cristina.jpeg'), linkedin: 'https://www.linkedin.com/in/joicecssantos/', github: 'https://github.com/Joice-crypto' },
    { name: 'Exemplo 4', role: 'Ex-Assessora de Gestão' },
  ]
};

const Company = () => {
  return (
    <>
      <AboutUsCompany />
      <Mvv />
      <Partners />
      <MemberCarousels members={membersData} />
    </>
  );
};

export default Company;
