import React from 'react';
import styles from '../styles/components/arrow.module.css';

const Arrow = ({ className, style, onClick, direction }) => (
  <div
    className={`${className} ${direction === 'next' ? styles.nextArrow : styles.prevArrow}`}
    style={{ ...style, opacity: 1, zIndex: 2 }}  // Garantir que a seta vermelha esteja visível com maior z-index
    onClick={onClick}
  >
    {direction === 'next' ? (
      <i className="fas fa-chevron-right" style={{ color: '#891F2A', fontSize: '24px' }}></i>  // Seta direita em vermelho
    ) : (
      <i className="fas fa-chevron-left" style={{ color: '#891F2A', fontSize: '24px' }}></i>   // Seta esquerda em vermelho
    )}
  </div>
);

export const NextArrow = (props) => <Arrow {...props} direction="next" />;
export const PrevArrow = (props) => <Arrow {...props} direction="prev" />;
