import React, { useState } from 'react';
import LinkButton from '../layouts/LinkButton';

/* Assets */
import case1 from '../assets/buffet.png'; 
import case2 from '../assets/arché.png'; 
import case3 from '../assets/tremkivoa.png'; 
import case4 from '../assets/milhas.png'; 
import case5 from '../assets/img-site-linked.jpg';

/* Styles */
import styles from '../styles/pages/cases.module.css';
import btn from '../styles/layouts/linkButton.module.css';

const Cases = () => {
  const [showAllCases, setShowAllCases] = useState(false);

  const handleLoadMoreClick = () => {
    setShowAllCases(!showAllCases);
  };

  return (
    <section className={styles.success_cases_section}>
      <h1 className={styles.title}>Cases de Sucesso</h1>
      <div className={styles.title_underline}></div>
      <h2 className={styles.subtitle}>Conheça nossos projetos</h2>
      <p className={styles.description}>Descubra como nossos serviços podem beneficiar sua empresa.</p>

      {/* Site Linked Case */}
      <div className={styles.case}>
        <img src={case5} alt="Site Linked" className={styles.case_image} />
        <div className={styles.case_text}>
          <h3>Site Linked</h3>
          <p>Plataforma para networking e exibição de portfólios, com funcionalidades dinâmicas para conectar profissionais.</p>
          <LinkButton to="/project-details/site-linked" text="← Explore Mais" className={btn.btnSecond} />
        </div>
      </div>

      {/* Arche Case */}
      <hr className={styles.divider} />
      <div className={styles.case}>
        <div className={styles.case_text}>
          <h3>Arche Empresa Junior</h3>
          <p>Site para uma empresa júnior de arquitetura em São João del-Rei, focado em gestão de projetos e presença digital.</p>
          <LinkButton to="/project-details/arche-empresa-junior" text="← Explore Mais" className={btn.btnSecond} />
        </div>
        <img src={case2} alt="Arche Empresa Junior" className={styles.case_image} />
      </div>

      {/* Buffet Case */}
      <hr className={styles.divider} />
      <div className={styles.case}>
        <img src={case1} alt="Buffet Sonho Real Tiradentes" className={styles.case_image} />
        <div className={styles.case_text}>
          <h3>Buffet Sonho Real Tiradentes</h3>
          <p>Site expositivo e portfólio para um buffet em Tiradentes, focado na modernização e aumento de reservas online.</p>
          <LinkButton to="/project-details/buffet-sonho-real-tiradentes" text="← Explore Mais" className={btn.btnSecond} />
        </div>
      </div>

      {/* Milhas Gerais Case */}
      <hr className={styles.divider} />
      <div className={styles.case}>
        <div className={styles.case_text}>
          <h3>Milhas Gerais</h3>
          <p>Site expositivo para o projeto Milhas Gerais da UFSJ, focado em aumentar a visibilidade e engajamento.</p>
          <LinkButton to="/project-details/milhas-gerais" text="← Explore Mais" className={btn.btnSecond} />
        </div>
        <img src={case4} alt="Milhas Gerais" className={styles.case_image} />
      </div>

      {/* Trem Ki Voa Case */}
      {showAllCases && (
        <>
          <hr className={styles.divider} />
          <div className={styles.case}>
            <img src={case3} alt="Trem Ki Voa Aerodesign" className={styles.case_image} />
            <div className={styles.case_text}>
              <h3>Trem Ki Voa Aerodesign</h3>
              <p>Site expositivo para o projeto de aerodesign da UFSJ, focado em aumentar a visibilidade e engajamento.</p>
              <LinkButton to="/project-details/trem-ki-voa-aerodesign" text="← Explore Mais" className={btn.btnSecond} />
            </div>
          </div>
        </>
      )}

      <button className={btn.btn} onClick={handleLoadMoreClick}>
        {showAllCases ? 'Carregar Menos' : 'Carregar Mais'}
      </button>
    </section>
  );
};

export default Cases;
