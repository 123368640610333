import React from 'react';
import styles from '../styles/pages/budget.module.css';

const BudgetForm = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const data = {
      nome: formData.get('nome'),
      email: formData.get('email'),
      telefone: formData.get('telefone'),
      servico: formData.get('servico'),
      mensagem: formData.get('mensagem'),
    };

    fetch('https://script.google.com/macros/s/AKfycbxNhGiCCjTK0_jjueNZ2IjHrtHASzXzaKBNdTDzwulzr4JLWn68tBWA88DbGWhzT8d8/exec', {
      method: 'POST',
      body: new URLSearchParams(data),
    })
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          alert('Pedido enviado com sucesso!');
        } else {
          alert('Ocorreu um erro ao enviar o pedido.');
        }
      })
      .catch(error => {
        console.error('Error:', error);
        alert('Ocorreu um erro ao enviar o pedido.');
      });

    e.target.reset(); // Reseta o formulário após o envio
  };

  return (
    <form className={styles.budgetForm} onSubmit={handleSubmit}>
      <label>
        Nome Completo
        <input type="text" name="nome" placeholder="Digite seu nome" required />
      </label>
      <label>
        Email
        <input type="email" name="email" placeholder="Digite seu email" required />
      </label>
      <label>
        Telefone
        <input type="tel" name="telefone" placeholder="(32) 99999-9999" required />
      </label>
      <label>
        Serviço
        <input type="text" name="servico" placeholder="Ex: Preciso de um site" required />
      </label>
      <label>
        Mensagem
        <textarea name="mensagem" placeholder="Faça uma breve descrição de sua mensagem" required></textarea>
      </label>
      <button type="submit" className={styles.submitButton}>Enviar</button>
    </form>
  );
};

export default BudgetForm;
