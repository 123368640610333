import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { NextArrow, PrevArrow } from '../components/Arrow';
import styles from '../styles/layouts/testimonials.module.css';

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className={styles.testimonials_section}>
      <h2>DEPOIMENTOS</h2>
      <div className={styles.title_underline}></div>
      <p>O que dizem nossos clientes?</p>
      <Slider {...settings} className={styles.slider}>
        <div className={styles.testimonial}>
          <h3>Gabriel Victor Costa Pereira </h3>
          <h4>Capitão na Trem Ki Voa UFSJ </h4>
          <p>“Quando a Linked atualizou nosso site, a estrutura e organização da equipe foram apresentadas de forma mais clara, objetiva e visual. A Linked se preocupou com a estruturação de todas áreas do site, o que facilitou o acesso e classificação das informações, o que é bem legal.“</p>
          <div className={styles.nps}>
            <span>NPS: 9.0</span>
            <a href="/project-details/trem-ki-voa-aerodesign">Leia mais</a>
          </div>
        </div>
        <div className={styles.testimonial}>
          <h3>Charles Barros</h3>
          <h4>Professor na UFSJ</h4>
          <p>"O site está sendo ferramenta fundamental na divulgação de informações sobre a pesquisa para reitor da UFSJ. O site atendeu perfeitamente as expectativas iniciais, pois já havia sido acordado previamente quais seriam os requisitos. A proposta do site era ser simples, objetivo e funcional e isto foi cumprido!"</p>
          <div className={styles.nps}>
            <span>NPS: 9.0</span>
          </div>
        </div>
        <div className={styles.testimonial}>
          <h3>Ana Beatriz Reis</h3>
          <h4> 8° Período de Fisioterapia UNIPTAN</h4>
          <p>"Através da parceria com a Linked, foi
 possível realizar um projeto de excelência, o qual facilitará os atendimentos e a clínica
 fisioterapêutica.
 Merecem total reconhecimento, vocês foram maravilhosos e
 foi ótimo poder realizar o projeto em parceria com vocês!"</p>
          <div className={styles.nps}>
            <span>NPS: 9.0</span>
          </div>
        </div>
        {/* <div className={styles.testimonial}>
          <h3>EXTRA</h3>
          <h4>Empresa Júnior de Outro Curso UFExemplo</h4>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus.</p>
          <div className={styles.nps}>
            <span>NPS: 9.0</span>
            <a href="#">Leia mais</a>
          </div>
        </div> */}
      </Slider>
    </section>
  );
};

export default Testimonials;
