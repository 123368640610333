import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/layouts/linkButton.module.css';

function LinkButton({ to, text, className }) {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Link className={`${styles.btn} ${styles[className]}`} to={to} onClick={handleClick}>
      {text}
    </Link>
  );
}

export default LinkButton;
